var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { padding: "20px 100px 20px 100px" },
      attrs: { align: "center" },
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        _vm._l(_vm.clients, function (client, index) {
          return _c(
            "v-card",
            {
              key: index,
              staticStyle: {
                margin: "20px",
                padding: "20px",
                "text-align": "left",
                width: "300px",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "24px",
                          "font-weight": "700",
                        },
                      },
                      [_vm._v(_vm._s(client.title))]
                    ),
                    _c(
                      "div",
                      { staticStyle: { "font-size": "14px", opacity: "0.8" } },
                      [_vm._v(_vm._s(client.subTitle))]
                    ),
                  ]),
                  _c("v-spacer"),
                  _c("v-chip", { attrs: { outlined: "" } }, [
                    _vm._v(_vm._s(client.chip)),
                  ]),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _vm._v(_vm._s(client.case)),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h1", [_vm._v("고객 사례")]),
      _c("h2", [_vm._v("공공 분야에서 금융분야까지")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }