var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticStyle: { padding: "20px" } },
        [
          _c("v-col", [
            _c(
              "div",
              { staticStyle: { height: "80.9vh", overflow: "auto" } },
              [
                _c("div", { staticClass: "tab-info" }, [
                  _vm._v(
                    "클라우드 환경으로의 전환은 단순하고 획일화된 기술 인프라 위주가 아닌, 우리 조직의 목표 시스템이 지향하는 주요 관점별 개선 지표에 최적화된 클라우드 네이티브한 시스템으로의 점진적 전환이 수반되어야 합니다. 이에 주요 관점별 목표수준 설정을 위해 아래 항목별 내용에 체크하세요. "
                  ),
                ]),
                _vm._l(_vm.chartData.topics, function (topic, topicIndex) {
                  return _c(
                    "div",
                    {
                      key: topicIndex,
                      staticStyle: { "margin-bottom": "40px" },
                    },
                    [
                      _c("h3", { staticStyle: { "margin-bottom": "10px" } }, [
                        _vm._v(_vm._s(topic.name)),
                      ]),
                      _vm._l(
                        topic.questions,
                        function (question, questionIndex) {
                          return _c(
                            "div",
                            {
                              key: questionIndex,
                              staticStyle: {
                                "margin-bottom": "10px",
                                padding: "10px 10px 0 0",
                                "border-bottom": "1px solid #ccc",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "font-weight": "700" } },
                                [_vm._v(_vm._s(question.title))]
                              ),
                              _c("div", [_vm._v(_vm._s(question.text))]),
                              _c("v-slider", {
                                staticClass: "tickLabels-txt",
                                attrs: {
                                  disabled:
                                    _vm.chartData.users &&
                                    _vm.chartData.users.length > 0,
                                  "tick-labels": _vm.getTickLabels(question),
                                  max: _vm.getTickLabels(question).length - 1,
                                  step: "1",
                                  ticks: "always",
                                  "tick-size": 4,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onSliderChange(question)
                                  },
                                },
                                model: {
                                  value: question.value,
                                  callback: function ($$v) {
                                    _vm.$set(question, "value", $$v)
                                  },
                                  expression: "question.value",
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                }),
              ],
              2
            ),
          ]),
          _c(
            "v-col",
            [
              _c("SpiderChart", {
                attrs: {
                  selectedProfile: _vm.selectedProfile,
                  chartData: _vm.chartData,
                  chartWidth: _vm.chartWidth,
                  chartHeight: _vm.chartHeight,
                  chartCenterX: _vm.chartCenterX,
                  chartCenterY: _vm.chartCenterY,
                  chartRadius: _vm.chartRadius,
                  labelOffset: _vm.labelOffset,
                  maxDataValue: _vm.maxDataValue,
                  pointRadius: _vm.pointRadius,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }