var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { "fixed-tabs": "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", { staticClass: "tab-title" }, [
            _vm._v("\n            검토결과\n        "),
          ]),
          _c("v-tab", { staticClass: "tab-title" }, [
            _vm._v("\n            참조 아키텍처\n        "),
          ]),
          _vm._l(_vm.guideTabs, function (item) {
            return _c("v-tab", { key: item.tab, staticClass: "tab-title" }, [
              _vm._v("\n            " + _vm._s(item.tab) + "\n        "),
            ])
          }),
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "guide-box",
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab-item", [
            _c(
              "div",
              { staticStyle: { padding: "0px" } },
              [
                _c("AllGuide", {
                  attrs: { selectedAllGuideStatus: _vm.selectedAllGuideStatus },
                }),
              ],
              1
            ),
          ]),
          _c("v-tab-item", [
            _c(
              "div",
              { staticStyle: { padding: "0px" } },
              [
                _c(
                  "v-row",
                  { staticClass: "ma-0 pa-0" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "get-the-guide-col-padding-right ma-0",
                        attrs: { cols: "6" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("h3", [_vm._v("ㆍ목표 성숙도 모델")]),
                            _c("v-img", {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                src: _vm.conversionGoalImage(
                                  _vm.slaResult.conversionGoal
                                ),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "start",
                                  "margin-left": "17px",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "서비스에 대한 클라우드 네이티브 적합성 검토결과,"
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-primary",
                                      staticStyle: { "font-weight": "700" },
                                    },
                                    [_vm._v(_vm._s(_vm.slaResult.count))]
                                  ),
                                  _vm._v(
                                    "개 이상 항목에서 적합성 조건을 충족하여\n                                    "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-primary",
                                      staticStyle: { "font-weight": "700" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.slaResult.cloudStatus) +
                                          _vm._s(
                                            _vm.getConversionResult(
                                              _vm.slaResult.conversionGoal
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "\n                                    - 목표 시스템 레벨에 따른 SLA 수준 : "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-primary",
                                      staticStyle: { "font-weight": "700" },
                                    },
                                    [_vm._v(_vm._s(_vm.slaResult.percentage))]
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                    - 월 허용가능 장애시간 : "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-primary",
                                      staticStyle: { "font-weight": "700" },
                                    },
                                    [_vm._v("약 " + _vm._s(_vm.slaResult.time))]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "div",
                          [
                            _c("h3", [
                              _vm._v(
                                "ㆍ전환 방법 : " +
                                  _vm._s(
                                    _vm.getConversionMethodInfo(
                                      _vm.slaResult.conversionMethod
                                    ).text
                                  )
                              ),
                            ]),
                            _c("v-img", {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                src: _vm.getConversionMethodInfo(
                                  _vm.slaResult.conversionMethod
                                ).imagePath,
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "start",
                                  "margin-left": "17px",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.slaResult.conversionText)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "get-the-guide-col-padding-right ma-0",
                        attrs: { cols: "6" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("h3", [_vm._v("ㆍ참조 아키텍처")]),
                            _vm._l(
                              _vm.referenceArchitecturegetImagePath(),
                              function (path) {
                                return [
                                  _c("v-img", {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { src: path },
                                  }),
                                ]
                              }
                            ),
                            _c("v-img", {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                src: _vm.infraGoalImage(
                                  _vm.slaResult.conversionGoal
                                ),
                              },
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._l(_vm.guideTabs, function (item) {
            return _c("v-tab-item", { key: item.tab }, [
              _c(
                "div",
                { staticStyle: { padding: "20px" }, attrs: { flat: "" } },
                [
                  _vm.goalLevels[item.tab_en] > 0 &&
                  _vm.markdownContentFolders[item.tab_en] &&
                  Object.keys(_vm.markdownContentFolders[item.tab_en]).length >
                    0
                    ? _c("div", {
                        staticClass: "markdown-body",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.markdownContentFolders[item.tab_en][
                              _vm.goalLevels[item.tab_en]
                            ]
                          ),
                        },
                      })
                    : _c("div", [
                        _vm._v(
                          "\n                    전환목표가 없습니다.\n                "
                        ),
                      ]),
                ]
              ),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }