var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      { staticStyle: { height: "100vh" } },
      [
        _c(
          "v-app-bar",
          {
            staticStyle: { height: "60px" },
            attrs: { elevation: "0", outlined: "", left: "", app: "" },
          },
          [
            _c("img", {
              staticStyle: {
                cursor: "pointer",
                "align-items": "left",
                width: "90px",
              },
              attrs: {
                src: "https://github.com/msa-ez/cloud-iq/assets/149130268/66649787-f394-49f9-8a72-8002997161c6",
              },
              on: {
                click: function ($event) {
                  return _vm.goHome()
                },
              },
            }),
            _c("v-spacer"),
            _vm.showProfileInfo
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openEditProfile()
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm.selectedUser
                                    ? _c("v-card-title", [
                                        _vm._v(
                                          "프로필 : " +
                                            _vm._s(_vm.selectedProfile) +
                                            " > " +
                                            _vm._s(_vm.selectedUser)
                                        ),
                                      ])
                                    : _c("v-card-title", [
                                        _vm._v(
                                          "그룹 : " +
                                            _vm._s(_vm.selectedProfile)
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3753966210
                    ),
                  },
                  [_c("span", [_vm._v("그룹/프로필 관리")])]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "v-main",
          [
            _c("router-view", {
              staticStyle: { height: "calc(100vh - 64px)" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }