var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticStyle: { padding: "20px" } },
        [
          _c("v-col", [
            _c(
              "div",
              { staticStyle: { height: "80.9vh", overflow: "auto" } },
              [
                _c("div", { staticClass: "tab-info" }, [
                  _vm._v(
                    "본 단계에서는 클라우드 네이티브 애플리케이션으로 전환되어야 할 기존 애플리케이션의 주요 관점별 현 수준을 평가하여, 전 단계에서 설정한 목표수준 대비 현 수준과의 갭을 가시화하고 분석합니다. 아래 7개의 관점별 현 수준에 해당하는 레벨에 체크해 주시기 바랍니다."
                  ),
                ]),
                _vm._l(
                  _vm.chartData.perspectives,
                  function (perspective, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { "margin-bottom": "40px" } },
                      [
                        _c("h2", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(_vm._s(perspective.name)),
                        ]),
                        _vm._l(
                          perspective.levels,
                          function (level, levelIndex) {
                            return _c("div", { key: levelIndex }, [
                              _c("h3", [
                                _vm._v("Level " + _vm._s(levelIndex + 1)),
                              ]),
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "20px" } },
                                _vm._l(
                                  level.checkpoints,
                                  function (checkpoint, checkpointIndex) {
                                    return _c(
                                      "label",
                                      { key: checkpointIndex },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "start",
                                              "margin-left": "15px",
                                              "line-height": "1.7",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: checkpoint.checked,
                                                  expression:
                                                    "checkpoint.checked",
                                                },
                                              ],
                                              staticStyle: {
                                                margin: "5px 5px 0 0",
                                              },
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  checkpoint.checked
                                                )
                                                  ? _vm._i(
                                                      checkpoint.checked,
                                                      null
                                                    ) > -1
                                                  : checkpoint.checked,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        checkpoint.checked,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            checkpoint,
                                                            "checked",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            checkpoint,
                                                            "checked",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        checkpoint,
                                                        "checked",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    return _vm.updateLevelCompletion(
                                                      perspective,
                                                      level
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "black" },
                                              },
                                              [_vm._v(_vm._s(checkpoint.text))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  }
                ),
                _c("div", [
                  _c("h2", [_vm._v("Result")]),
                  _vm.isAllLevelsCompleted
                    ? _c("p", [_vm._v("All levels are completed!")])
                    : _c("p", [_vm._v("Some levels are not completed.")]),
                ]),
              ],
              2
            ),
          ]),
          _c(
            "v-col",
            [
              _c("SpiderChart", {
                attrs: {
                  selectedProfile: _vm.selectedProfile,
                  chartData: _vm.chartData,
                  chartWidth: _vm.chartWidth,
                  chartHeight: _vm.chartHeight,
                  chartCenterX: _vm.chartCenterX,
                  chartCenterY: _vm.chartCenterY,
                  chartRadius: _vm.chartRadius,
                  labelOffset: _vm.labelOffset,
                  maxDataValue: _vm.maxDataValue,
                  pointRadius: _vm.pointRadius,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }