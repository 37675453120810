var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { padding: "0px 30px 0px 30px" } },
      [
        _c(
          "v-card-title",
          {
            staticClass: "text-center justify-center main-title",
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "align-items": "center",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [_vm._v("\n\t\t\t\t클라우드 네이티브 전환 가이드라인\n\t\t\t")]
            ),
            _c("div", [_vm._v("(Beta)")]),
            _c("iframe", {
              attrs: {
                width: "40%",
                height: "300",
                src: "https://www.youtube.com/embed/oCKu8ZsGkjw",
                title: "YouTube video player",
                frameborder: "0",
                allowfullscreen: "",
              },
            }),
          ]
        ),
        _c(
          "v-row",
          { staticClass: "ma-0 pa-0" },
          [
            _vm._l(_vm.steps, function (step, index) {
              return _c(
                "v-col",
                {
                  key: index,
                  attrs: { cols: "12", lg: "3", md: "3", sm: "6" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "card-box",
                      on: {
                        click: function ($event) {
                          return _vm.goToStep(step.path)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("h2", { staticClass: "step-index" }, [
                            _vm._v("Step. " + _vm._s(index + 1)),
                          ]),
                          _c("Icon", {
                            staticClass: "card-icon",
                            attrs: { icon: step.icon },
                          }),
                          _c("h1", { staticClass: "step-name" }, [
                            _vm._v(_vm._s(step.name)),
                          ]),
                          _c("div", { staticClass: "step-name-en" }, [
                            _vm._v(_vm._s(step.name_en)),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            _c(
              "v-col",
              { attrs: { cols: "12", lg: "3", md: "3", sm: "6" } },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "card-box",
                    on: {
                      click: function ($event) {
                        return _vm.goToStep("/case-study")
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("Icon", {
                          staticClass: "card-icon",
                          attrs: { icon: "fluent:briefcase-search-20-regular" },
                        }),
                        _c("h1", { staticClass: "step-name" }, [
                          _vm._v("전환 사례"),
                        ]),
                        _c("div", { staticClass: "step-name-en" }, [
                          _vm._v("Case Study"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "v-footer",
          {
            staticClass: "footer",
            staticStyle: { "background-color": "transparent" },
          },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-btn",
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goToStep("/all-guide")
                      },
                    },
                  },
                  [_vm._v("전환가이드 전체 보기")]
                ),
                _c("v-divider", {
                  staticClass: "mx-2",
                  attrs: { vertical: "" },
                }),
                _c("v-btn", { attrs: { text: "" } }, [_vm._v("About")]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }