var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-stepper",
        {
          staticStyle: { "box-shadow": "none" },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _vm._l(_vm.components.length, function (n) {
                return [
                  _c(
                    "v-stepper-step",
                    {
                      key: `${n}-step`,
                      attrs: {
                        complete: _vm.currentStep > n,
                        step: n,
                        editable: "",
                      },
                    },
                    [
                      _c("div", [
                        _c("div", [_vm._v("Step " + _vm._s(n))]),
                        _c("div", [_vm._v(_vm._s(_vm.components[n - 1].name))]),
                      ]),
                    ]
                  ),
                  n !== _vm.components.length
                    ? _c("v-divider", { key: n })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-stepper-items",
            { staticClass: "step-box" },
            _vm._l(_vm.components.length, function (n) {
              return _c(
                "v-stepper-content",
                {
                  key: `${n}-content`,
                  staticStyle: { padding: "0px" },
                  attrs: { step: n },
                },
                [
                  _c(
                    "div",
                    [
                      _c(_vm.components[n - 1].component, {
                        tag: "component",
                        attrs: {
                          selectedProfile: _vm.selectedProfile,
                          chartData: _vm.chartData,
                          selectedAllGuideStatus: _vm.selectedAllGuideStatus,
                        },
                        on: { saveProfiles: _vm.saveProfiles },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }