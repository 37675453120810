var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.selectedMarkdownContent
        ? _c("v-simple-table", [
            _c("thead", [
              _c("tr", { staticClass: "all-guide-table-head" }, [
                _c(
                  "th",
                  { staticStyle: { "text-align": "left !important" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "separation-box" },
                      [
                        _c("div", [_vm._v("목표수준")]),
                        _c("Icon", {
                          staticStyle: { color: "rgb(25, 118, 210)" },
                          attrs: {
                            icon: "fluent:animal-rabbit-24-regular",
                            width: "24",
                            height: "24",
                          },
                        }),
                        _vm._v(" /\n                        "),
                        _c("div", [_vm._v("현수준")]),
                        _c("Icon", {
                          staticStyle: { color: "rgba(255, 183, 77, 1)" },
                          attrs: {
                            icon: "fluent:animal-turtle-16-regular",
                            width: "24",
                            height: "24",
                          },
                        }),
                        _vm._v(" /\n                        "),
                        _c("div", [_vm._v("달성수준")]),
                        _c("Icon", {
                          staticStyle: { color: "green" },
                          attrs: {
                            icon: "fluent:animal-rabbit-24-regular",
                            width: "24",
                            height: "24",
                          },
                        }),
                        _c("Icon", {
                          staticStyle: { color: "green" },
                          attrs: {
                            icon: "fluent:animal-turtle-16-regular",
                            width: "24",
                            height: "24",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("th", [_vm._v("Level 1")]),
                _c("th", [_vm._v("Level 2")]),
                _c("th", [_vm._v("Level 3")]),
                _c("th", [_vm._v("Level 4")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.guide, function (guideItem, guideIndex) {
                return _c(
                  "tr",
                  { key: guideIndex },
                  [
                    _c("td", [_vm._v(_vm._s(guideItem.name))]),
                    _vm._l(guideItem.levels, function (level) {
                      return _c(
                        "td",
                        {
                          key: level.level,
                          staticClass: "all-guide-view",
                          on: {
                            click: function ($event) {
                              return _vm.navigate(
                                guideItem.name_en,
                                level.level
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticStyle: {
                                padding: "20px",
                                margin: "10px",
                                "text-align": "center",
                              },
                              style: _vm.checkPathMatch(level.path).style,
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0 pa-0",
                                  staticStyle: {
                                    position: "absolute",
                                    right: "0px",
                                    top: "0px",
                                  },
                                },
                                _vm._l(
                                  _vm.getIconForPath(level.path),
                                  function (icon) {
                                    return _c("Icon", {
                                      key: icon,
                                      attrs: {
                                        icon: icon,
                                        width: "24",
                                        height: "24",
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "font-weight": "900" } },
                                [_vm._v(_vm._s(level.name))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ])
        : [
            _c(
              "v-card",
              { staticClass: "pa-0 ma-0" },
              [
                _c(
                  "v-row",
                  { staticClass: "ma-0 pa-0" },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { icon: "", text: "", color: "black" },
                        on: {
                          click: function ($event) {
                            return _vm.goBack()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "markdown-body",
                  staticStyle: {
                    height: "calc(100vh - 220px)",
                    overflow: "auto",
                  },
                  domProps: { innerHTML: _vm._s(_vm.selectedMarkdownContent) },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }